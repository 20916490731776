
export const getNextDay = ({ day }: { day: string }): { day: string; number: string; timestamp: number } => {
    try {
        const today = new Date();
        const todayIndex = today.getDay();

        let targetIndex = 0;

        if (day === "mardi") {
            targetIndex = 2;
        } else if (day === "vendredi") {
            targetIndex = 5;
        }

        let daysUntilTarget = targetIndex - todayIndex;
        if (daysUntilTarget <= 0) {
            daysUntilTarget += 7;  //Next week
        }

        const targetDate = new Date();
        targetDate.setDate(today.getDate() + daysUntilTarget);

        return {
            day: day,
            number: targetDate.getDate().toString(),
            timestamp: targetDate.getTime(),
        };

    } catch (error) {
        throw error;
    }
};

export const getMonthFromTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return (date.getMonth() + 1).toString().padStart(2, '0');
};

export const getYearFromTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.getFullYear().toString();
};
