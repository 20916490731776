import { RegionModel } from "models/RegionModel";
import Aura from "assets/Aura.png"
import Occ from "assets/occitanie.png"

export const getRegionsAvailable = (): RegionModel[] => {
    const regions = [
        {
            id: "Auvergne-Rhône-Alpes",
            label: "Auvergne-Rhône-Alpes",
            description: "Pré-inscription ouverte !",
            text: "Dès le 17 février profitez de Mullo dans votre région. Si vous n'êtes pas encore pré-inscrit, n'hésitez pas à montrer votre intéret pour notre déploiement.",
            imageUrl: Aura,
            groups: []
        },
        {
            id: "Île-de-France",
            label: "Île-de-France",
            description: "Découvrez les meilleurs produits locaux de la région Île-de-France.",
            text: "",
            imageUrl: "https://zupimages.net/up/24/42/a23v.png",
            groups: [
                {
                    id: "org_2mA6fQMQobyFQFjByLri9x3tTi0",
                    label: "Seine-et-Marne",
                    description: "Dans la belle campagne de Seine-et-Marne, à quelques dizaines de kilomètres de Paris, 12 producteurs cultivent et produisent de nombreux produits biologiques et de qualité.",
                    imageUrl: "https://zupimages.net/up/24/42/a23v.png",
                    deliveryDays: [
                        {
                            value: "tuesday",
                            label: "mardi"
                        },
                        {
                            value: "friday",
                            label: "vendredi"
                        }
                    ],
                    deliveryScope: "Paris et petite couronne"
                },
            ]
        },
        {
            id: "Occitanie",
            label: "Occitanie",
            description: "Cette région sera disponible courant 2025",
            text: "Notre lancement en Occitanie est prévue prochainement : restez à l'écoute !",
            imageUrl: Occ,
            groups: []
        }
    ];
    return regions;
};
