import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from 'react-hook-form';
import { z } from "zod"
import logo from 'assets/logo.png';

import { Button } from "../components/ui/button"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { postNewUser } from 'api/signup/SignupService';
import { Eye, EyeOff, Tractor, Truck } from 'lucide-react';

const formSchema = z.object({
  firstName: z.string().min(2, {
    message: "Votre prénom est surement composé d'au moins 2 caractères.",
  }),
  lastName: z.string().min(2, {
    message: "Votre nom est surement composé d'au moins 2 caractères.",
  }),
  factoryName: z.string(),
  email: z.string().email().min(5),
  phoneNumber: z.string(),
  password: z.string().min(5, {
    message: "Votre mot de passe doit au moins comporter 5 caractères.",
  }),
  siret: z.string(),
  userType: z.string(),
})

const SignUp: React.FC = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      factoryName: "",
      email: "",
      password: "",
      // phoneNumber: "",
      userType: "",
      siret: ""
    },
  })

  const inputs = [
    {
      name: "siret",
      title: "Siret",
      placeholder: "",
      col: 2,
      type: 'text'
    },
    {
      name: "firstName",
      title: "Prénom",
      placeholder: "",
      col: 1,
      type: 'text'
    },
    {
      name: "lastName",
      title: "Nom",
      placeholder: "",
      col: 1,
      type: 'text'
    },
    {
      name: "factoryName",
      title: "Nom de l'entreprise",
      placeholder: "",
      col: 2,
      type: 'text'
    },
    {
      name: "email",
      title: "Email",
      placeholder: "",
      col: 2,
      type: 'email'
    },
    {
      name: "phoneNumber",
      title: "Numéro de téléphone",
      placeholder: "+33 6 00 00 00 00",
      col: 2
    },
    {
      name: "password",
      title: "Mot de passe",
      placeholder: "",
      col: 2,
      type: 'password'
    }
  ]

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });

  const { handleSubmit, formState: { isValid } } = form;

  const formatPhoneNumber = (phoneNumber: string) => {
    let cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('33')) {
      cleaned = '+' + cleaned;
    } else if (cleaned.startsWith('0')) {
      cleaned = '+33' + cleaned.slice(1);
    }
    const formatted = cleaned.replace(/(\d{2})(?=\d)/g, '$1 ');
    return formatted.trim();
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(values.phoneNumber);
      values.phoneNumber = formattedPhoneNumber;
      const query = await postNewUser(values);
      if (!query.hasError) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          navigate('/connexion');
        }, 3000);
      } else {
        setError("Une erreur est survenue lors de l'inscription.")
      }
    } catch (error: any) {
      setError("Erreur lors de l'inscription.")
    }
  };

  const getUserRole = useCallback((type: string) => {
    form.setValue("userType", type, { shouldValidate: true });
    setStep(2);
  }, [form]);

  return (
    <FormProvider {...methods}>
      <div className="min-h-screen flex justify-center items-center flex-col">
        <div className='max-w-[400px] rounded-xl m-4 shadow-xl'>
          <div className='px-2 py-8 rounded-xl relative z-10'>
            <img src={logo} className='mx-auto mb-6' alt="Logo Mullo" />
            <hr className='mb-3' />
            <h1 className='px-4 text-primary text-2xl text-center font-bold'>Créez votre compte</h1>
            <p className='px-4 text-primary text-sm text-center mt-2 mb-8 opacity-60'>
              {step === 1 ?
                "Bienvenue ! Pourquoi voulez-vous utiliser Mullo ?"
                : "Complétez le formulaire ci-dessous et commencez à utiliser Mullo."}
            </p>
            {!loading ?
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                <div className='container grid grid-cols-2 gap-4'>
                  {step === 2 ?
                    <>

                      {error &&
                        <div className='col-span-2 text-center text-red-600 font-bold mb-4'>
                          <p>{error}</p>
                        </div>
                      }
                      {inputs.map((input: any, i) => (
                        <div key={i} className={`col-span-${input.col}`}>
                          <FormField
                            control={form.control}
                            name={input.name}
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabel>{input.title}</FormLabel>
                                <FormControl>
                                  { input.type === 'password' ?
                                    <div className='relative'>
                                      <Input placeholder={input.placeholder} type={visiblePassword ? 'text' : 'password'} {...field} />
                                      {visiblePassword ?
                                        <Eye 
                                          size={32} 
                                          onClick={() => setVisiblePassword(!visiblePassword)} 
                                          className='absolute right-1 top-1 hover:bg-gray-100 cursor-pointer rounded-full p-1'
                                        />
                                      :
                                        <EyeOff
                                          size={32} 
                                          onClick={() => setVisiblePassword(!visiblePassword)} 
                                          className='absolute right-1 top-1 hover:bg-gray-100 cursor-pointer rounded-full p-1'
                                        />
                                      }
                                    </div>
                                    :
                                    <Input placeholder={input.placeholder} type={input.type} {...field} />
                                  }
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      ))}

                      <div className='col-span-2'>
                        <Button
                          type="submit"
                          className={`w-full ${!isValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={!isValid}
                        >
                          Créer
                        </Button>
                      </div>
                    </>
                    :
                    <>
                      <div onClick={() => getUserRole("producer")} className='col-span-2 p-2 border-2 border-primary rounded-lg cursor-pointer hover:bg-slate-100 flex items-center'>
                        <div className='w-1/3 flex justify-center items-center'>
                          <Tractor size={55} strokeWidth={1.1} color='#456D4D' />
                        </div>
                        <h3 className='text-sm'>Je vends mes produits et je reçois des commandes.</h3>
                      </div>
                      <div onClick={() => getUserRole("buyer")} className='col-span-2 p-2 border-2 border-primary rounded-lg cursor-pointer hover:bg-slate-100 flex items-center'>
                        <div className='w-1/3 flex justify-center items-center'>
                          <Truck size={55} strokeWidth={1.1} color='#456D4D' />
                        </div>
                        <h3 className='text-sm'>J'achète des produits ultra locaux et je me fais livrer.</h3>
                      </div>
                    </>
                  }
                </div>
              </form>
              :
              <p className="text-center text-lg font-semibold text-green-600">
                Félicitations ! Votre inscription a été enregistrée avec succès.
                <br />
                Vous serez bientôt redirigé vers le formulaire de connexion.
              </p>
            }
          </div>

          <div className='bg-gray-100 px-2 pt-6 pb-5 mt-[-10px] rounded-b-xl relative z-0'>
            <p className='text-center text-sm'>
              Vous avez déjà un compte ? <a href="/connexion" className='text-primary cursor-pointer hover:underline'>connectez-vous</a>
            </p>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default SignUp;