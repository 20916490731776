import React, { useState, useEffect } from 'react';
import SimpleProducerCard from '../Cards/SimpleProducerCard';
import defaultUserImage from 'assets/logo.png';
import { ProducerModel } from 'models/ProducerModel';
import useProducersService from 'api/producers/ProducerService';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'components/ui/carousel';
import { useParams } from 'react-router-dom';


const ProducerList: React.FC = () => {
  const [producers, setProducers] = useState<ProducerModel[] | []>([]);
  let { organizationId } = useParams();
  const { getOrganizationProducers } = useProducersService();

  useEffect(() => {
    const getProducers = async () => {
      const organizationProducers = await getOrganizationProducers({ organizationId: organizationId ? organizationId : 'org_2mA6fQMQobyFQFjByLri9x3tTi0' });
      setProducers(organizationProducers);
    };
    getProducers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <div className='container px-0 sm:px-20 md:px-24 lg:px-40'>
      <h1 className="text-2xl font-bold text-black text-center">{producers.length} producteurs sur cette boutique</h1>
      <h2 className="text-lg font-normal text-center mt-1">En direct de leur production</h2>
      <Carousel
        opts={{
          align: "start",
        }}
        className="w-full max-w-3xl px-4 pt-4 mt-5 mx-auto"
      >
        <CarouselContent>
          {producers.map((producer, index) => (
            <CarouselItem key={index} className="p-1 basis-1/2 sm:basis-1/3 lg:basis-1/4">
              <SimpleProducerCard
                key={index}
                image={producer?.imageUrl ? producer.imageUrl : defaultUserImage}
                producerName={producer.fullName}
                factoryName={producer.factoryName}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious variant="contain-white" className='left-2 sm:left-0 border-2 mt-[-10px]' />
        <CarouselNext variant="contain-white" className='right-2 sm:right-0 border-2 mt-[-10px]' />
      </Carousel>
    </div>
  );
};

export default ProducerList;