import React from 'react';
import background from '../../assets/marketplace_bg.png';
import SearchBar from '../Globals/Search/SearchBar';

const MarketplaceSearch: React.FC = () => {

  return (
    <div className="w-full max-w-full h-screen flex justify-center items-center relative overflow-hidden" style={{ backgroundColor: "#F3DFAC" }}>
      <div className="absolute top-0 left-0 w-full h-full">
        <img src={background} className="absolute bottom-0 left-0 w-full h-50 object-cover" alt="" />
      </div>
      <div className="relative z-10 text-center max-w-full">
        <h1 className="text-5xl/tight font-bold line">
          <span>Des produits en direct <br />des producteurs de nos régions</span>
        </h1>
        <div className="relative z-10 text-center mt-8">
          <div className="w-full max-w-full">
            <SearchBar size="medium" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceSearch;