import { Apple, Beef, Carrot, Egg, Milk, PlusIcon, Fish, IceCreamCone } from 'lucide-react';
import { CategoryModel } from 'models/CategoryModel';

export const getAllCategories = (): CategoryModel[] => {
    const allCategories = [
        { id: "Fruits", icon: <Apple />, name: "Fruits" },
        { id: "Légumes", icon: <Carrot />, name: "Légumes" },
        { id: "Crèmerie", icon: <IceCreamCone />, name: "Crèmerie" },
        // { id: "Champignons", icon: <TreeDeciduous />, name: "Champignons" },
        { id: "Épicerie", icon: <Egg />, name: "Épicerie" },
        { id: "Boissons", icon: <Milk />, name: "Boissons" },
        { id: "Viandes", icon: <Beef />, name: "Viandes" },
        { id: "Poissons", icon: <Fish />, name: "Poissons" },
        { id: "Autre", icon: <PlusIcon />, name: "Autre" },
    ];
    return allCategories;
};

export const getCategoryById = ({ categoryId }: { categoryId: string }): CategoryModel => {
    const allCategories = getAllCategories();
    return allCategories.filter((category) => category.id === categoryId)[0];
};
