import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MarketplaceDisplayProduct from './MarketplaceDisplayProduct';
import { Button } from 'components/ui/button';

const MarketplaceProduct: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams()

  const redirectToProducts = () => {
    navigate(`/boutique/${organizationId}/produits`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container px-2 py-8 sm:py-16 lg:py-32">
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-3xl font-bold text-black text-center">Des produits locaux et en circuit court de qualité</h1>
        <h2 className="text-xl font-normal text-center">Livrés à votre adresse</h2>
        <Button
          className="mt-2 p-2 bg-white text-black border border-solid border-[#E5E5E5] rounded-md text-sm px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-100 hover:border-gray-300"
          onClick={redirectToProducts}
        >
          Visiter la boutique
        </Button>
      </div>
      <div className="my-4">
        <MarketplaceDisplayProduct />
      </div>
      <div className="flex flex-col items-center space-y-4">
        <Button
          className="mt-2 p-2 bg-white text-black border border-solid border-[#E5E5E5] rounded-md text-sm px-4 py-2 transition duration-300 ease-in-out hover:bg-gray-100 hover:border-gray-300"
          onClick={redirectToProducts}
        >
          Visiter la boutique
        </Button>
      </div>
    </div>
  );
};

export default MarketplaceProduct;