import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ProductModel } from 'models/ProductModel';
// import useProductsService from './products/ProductService';
import { FilterModel } from 'models/FilterModel';
import useProducersService from './producers/ProducerService';

interface ContextProviderType {
  selectedCategory: string | null;
  setSelectedCategory: (category: string) => void;
  allProducts: ProductModel[] | null;
  filters: FilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<FilterModel[]>>;
}

const ProviderContext = createContext<ContextProviderType | undefined>(undefined);

export const ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [allProducts, setAllProducts] = useState<ProductModel[] | null>(null);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const { getOrganizationProducts } = useProducersService()
  // const { getAllProducts } = useProductsService();
  const [orgaId, setOrgaId] = useState<string | null>(null)

    useEffect(() => {
        const handleUrlChange = () => {
          const currentUrl = window.location.pathname;
          const currentOrg = currentUrl.match(/org[^/]*/);
          setOrgaId(currentOrg && currentOrg[0])
        };

        // Call on initial render
        handleUrlChange();

        // Add event listener for URL changes
        window.addEventListener('popstate', handleUrlChange);
        
        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, []);


  useEffect(() => {
    const getProducts = async (id: string) => {
      const products = await getOrganizationProducts({organizationId: id });
      setAllProducts(products);
    };

    if (orgaId) {
      getProducts(orgaId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgaId]);

  return (
    <ProviderContext.Provider value={{ selectedCategory, setSelectedCategory, allProducts, filters, setFilters }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useContextProvider = () => {
  const context = useContext(ProviderContext);
  if (!context) {
    throw new Error("useContextProvider must be used within a ContextProvider");
  }
  return context;
};