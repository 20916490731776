import React from 'react';
import CategoryList from '../Globals/Caroussels/CategoryList';
import InformationCard from '../Globals/Cards/InformationCard';
import card1 from '../../assets/card1.png';
import card2 from '../../assets/card2.png';
import card3 from '../../assets/card3.png';
import { useNavigate } from 'react-router-dom';

const MarketplaceCategory: React.FC = () => {
  const navigate = useNavigate();
  const path = window.location.pathname.replace(/^\/boutique\//, '')

  const cardsData = [
    {
      image: card1,
      title: "On est ouvert !",
      subtitle: "Commandez dès maintenant !",
      backgroundColor: "#92BA9A",
      btnText: "j'en profite",
      navigate: () => navigate(`/boutique/${path}/produits`),
      disabled: false
    },
    {
      image: card2,
      title: "Des produits frais",
      subtitle: "En direct des producteurs !",
      backgroundColor: "#F3DFAC",
      btnText: "consulter",
      navigate: () => navigate(`/boutique/${path}/produits`),
      disabled: false
    },
    {
      image: card3,
      title: "Mes favoris",
      subtitle: "Les produits et producteurs dans ma liste",
      backgroundColor: "#F1A9AF",
      btnText: "bientôt disponible",
      navigate: () => navigate(`/boutique/${path}/produits`),
      disabled: true
    }
  ];

  return (
    <div>
      <CategoryList />
      <div className="grid grid-cols-6 gap-4 container mx-auto mt-8">
        {cardsData.map((card, index) => (
          <div className="col-span-6 md:col-span-2" key={index}>
            <InformationCard
              key={index}
              image={card.image}
              title={card.title}
              subtitle={card.subtitle}
              onClick={card.navigate}
              backgroundColor={card.backgroundColor}
              btnText={card.btnText}
              disabled={card.disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketplaceCategory;