import React, { useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { useContextProvider } from "api/ContextProvider";
import { useNavigate, useParams } from 'react-router-dom';

interface SearchBarProps {
  size: 'full' | 'medium';
}


// // Click outside function
function useOutsideAlerter(ref: React.RefObject<HTMLElement>, setSearchTerm: React.Dispatch<React.SetStateAction<string>>) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setSearchTerm('');
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}


const SearchBar: React.FC<SearchBarProps> = ({ size }) => {
  const navigate = useNavigate()
  const { allProducts } = useContextProvider();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  let { organizationId } = useParams();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setSearchTerm);

  // Filter products
  useEffect(() => {
    if (searchTerm.length >= 4 && allProducts && allProducts.length > 0) {
      const filtered = allProducts.filter((product: any) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      // Only update state if the filtered result changes, prevent infinite loops
      if (JSON.stringify(filtered) !== JSON.stringify(filteredProducts)) {
        setFilteredProducts(filtered);
      }
    } else if (filteredProducts.length !== 0) {
      setFilteredProducts([]);
    }
  }, [searchTerm, allProducts, filteredProducts]);
  

  const handleProductSelect = (productId: string, productName: string) => {
    setSearchTerm('');
    setFilteredProducts([]);
    navigate(`${organizationId}/produits?produit=${productId}`)
  };

  return (
    <div className='relative'>
      {size === 'full' && (
        <div className="relative flex items-center w-full rounded-sm bg-[#F9FAFB] p-2">
          <input
            id="searchInput"
            type="text"
            className="flex-grow text-sm text-gray-700 placeholder-italic placeholder-gray-500 bg-transparent focus:outline-none"
            placeholder="Rechercher un article. ex: salades, farine, épicerie"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            id="searchBtn"
            className="ml-2 px-4 py-2 bg-[#273E2C] text-white rounded-md text-sm font-semibold"
            type="button"
            size="sm"
          >
            Rechercher
          </Button>
        </div>
      )}

      {size === 'medium' && (
        <div id="searchDivMedium" className="flex items-center rounded-md bg-[#f9fafb] py-3 px-6 gap-x-2" style={{ padding: '12px 24px' }}>
          <input
            id="searchInputMedium"
            type="text"
            placeholder="Rechercher un article. Ex: salades, farine, épicerie... "
            className="flex-grow text-sm italic placeholder-gray-500"
            style={{
              backgroundColor: '#f9fafb',
              border: 'none',
              outline: 'none',
              fontSize: '16px',
              color: '#999999',
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            id="searchBtnMedium"
            className="bg-[#273E2C] text-white rounded-md py-2 px-4"
            style={{ borderRadius: '5px' }}
          >
            Rechercher
          </Button>
        </div>
      )}

      {searchTerm.length >= 4 && (
        <ul ref={wrapperRef} id="searchResults" className="px-2 absolute w-full max-h-80 overflow-y-scroll mt-1 bg-white border border-gray-200 rounded-lg z-10">
          {filteredProducts.map((product) => (
            <li
              id={`productItem-${product.productId}`}
              key={product.productId}
              className="flex items-center py-2 cursor-pointer hover:bg-gray-100 border-b-2 border-neutral-100"
              onClick={() => handleProductSelect(product.productId, product.productName)}
            >
              <img className='w-12 h-12 rounded-sm mr-3' src={product.image} alt=''/>
              <div className="flex flex-col">
                <span id={`productName-${product.productId}`} className="font-bold text-gray-900">{product.productName}</span>
                <p id={`productPrice-${product.productId}`} className="text-gray-700">
                  {product.price ? `${(product.price*product.minimum).toFixed(2)} €` : 'Prix non disponible'} -
                  <span className='text-sm'> {product.price.toFixed(2)}€/{product.unitFormat}</span>
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;