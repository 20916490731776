import React, { useState } from 'react';
import map from 'assets/france-2.png';
import { getRegionsAvailable } from 'api/RegionService';
import { RegionModel } from 'models/RegionModel';
import { PackageCheck, Truck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/ui/button';

const MarketHome: React.FC = () => {
  const regions = getRegionsAvailable();
  const [selectedRegion, setSelectedRegion] = useState<RegionModel | null>(regions[0]);
  const navigate = useNavigate();

  const handleRegionSelect = (region: RegionModel) => {
    setSelectedRegion(region);
  };

  const navigateToGroup = (groupId: string) => {
    navigate(`/boutique/${groupId}`);
  }

  return (
    <div className="relative min-h-screen bg-[#F3DFAC] flex items-center justify-center pt-[80px]">
      <img
        src={map}
        alt="Produits locaux"
        className="fixed top-[5%] right-[-5%] xl:right-[3%] h-[80vh] w-auto object-cover pointer-events-none opacity-0 sm:opacity-100"
      />


      <div className="relative w-[95%] xl:w-[85%] px-0 md:px-16 lg:px-24 flex flex-col items-center">

        <div className='flex items-center justify-between w-full p-8 bg-primary rounded-md mt-5 flex-col md:flex-row'>
          <div>
            <h1 className="text-center md:text-left text-3xl sm:text-4xl font-bold mb-3 text-white">Notre gamme nationale</h1>
            <p className="text-center md:text-left text-md font-normal text-white">
              Disponible en Auvergne-Rhône-Alpes dès maintenant !
            </p>
          </div>
          <Button href="org_2sfLSwpw3eOD3uUuvBWqQVaqMrA" className='bg-secondary text-black ml-2 mt-5 md:mt-0'>Consulter les produits</Button>
        </div>
        
        <div className="w-full mt-12">
          <h2 className="text-3xl font-bold mb-3">
            Nos boutiques locales <br /> pour les professionnels
          </h2>
          <p className="text-md font-normal">
            En direct des producteurs, retrouvez <br />
            des produits frais et de qualité.
          </p>
        </div>


        <div className="flex w-full flex-col sm:flex-row bg-[#F4F4F5] p-1 mt-5 rounded-lg">
          {regions.map((region) => (
            <button
              key={region.id}
              className={`flex-1 m-1 p-2 text-lg font-semibold rounded ${selectedRegion?.id === region.id ? 'bg-[#639C6E] text-white' : 'text-[#B3B3B3] bg-transparent'}`}
              onClick={() => handleRegionSelect(region)}
            >
              {region.label}
            </button>
          ))}
        </div>

        <div className="w-full bg-[#F4F4F5] p-4 rounded-lg mt-5 mb-40">
          {selectedRegion && (
            <>
              {selectedRegion.groups.length > 0 ? (
                selectedRegion.groups.map((group, index) => (
                  <div
                    key={group.id}
                    className={`w-full flex flex-col lg:flex-row items-center gap-6 py-6 px-6 md:px-10 ${index % 2 === 1 ? 'sm:flex-row-reverse' : ''}`}
                  >
                    <div className="w-full sm:w-[80%] lg:w-[50%] 2xl:w-[40%] flex justify-center sm:justify-end">
                      <img
                        src={group.imageUrl}
                        alt={`la région ${group.label}`}
                        className="w-full object-contain sm:object-cover rounded-sm"
                      />
                    </div>
                    <div className="w-full sm:w-[80%] lg:w-[50%] flex flex-col items-start p-4">
                      <p className="text-[#9C1923] font-bold text-2xl">{selectedRegion.label}</p> {/* Texte plus gros pour la région */}
                      <h2 className="text-[#273E2C] text-3xl font-bold mt-2">{group.label}</h2>
                      <p className="text-sm text-[#273E2C] mt-1">{group.description}</p>
                      <div className="flex justify-start w-full mt-4">
                        <div className="text-center mr-8">
                          <Truck color="#A0A0A0" />
                          <p className="text-sm font-semibold text-[#A0A0A0] mt-1">
                            {group.deliveryScope}
                          </p>
                        </div>
                        <div className="text-center">
                          <PackageCheck color="#A0A0A0" />
                          <p className="text-sm font-semibold text-[#A0A0A0] mt-1">
                            Livraison {group.deliveryDays.map((day, i) => (
                              <span key={day.value}>
                                {day.label}{i < group.deliveryDays.length - 1 ? ' et ' : ''}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => navigateToGroup(group.id)}
                        className="bg-[#9C1923] text-white font-semibold py-2 px-4 rounded mt-4"
                      >
                        Voir la boutique
                      </button>
                    </div>
                  </div>

                ))
              ) : (
                <div className="w-full flex flex-col sm:flex-row items-center gap-6 mt-8 p-6">
                  <div className="w-full sm:w-[45%]">
                    <p className="text-[#9C1923] font-bold text-xl">{selectedRegion.label}</p>
                    <h2 className="text-[#273E2C] text-3xl font-bold mt-2">{selectedRegion.description}</h2>
                    <p className="text-sm text-[#273E2C] mt-1">
                      {selectedRegion.text}
                    </p>
                  </div>
                  <div className="w-full sm:w-[45%] flex justify-center sm:justify-end">
                    <img
                      src={selectedRegion.imageUrl}
                      alt={`région ${selectedRegion.label}`}
                      className="w-full sm:w-[90%] h-auto rounded-lg object-cover"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketHome;