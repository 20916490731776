import React, { useState, SetStateAction, Dispatch, useEffect } from 'react';
import logo from 'assets/logo.png';
import { getMonthFromTimestamp, getYearFromTimestamp } from 'api/DateService';
import { DateModel } from 'models/DateModel';
import { Button } from 'components/ui/button';
import { useCart } from 'services/CartService';
import { useUser } from '@clerk/clerk-react';
import useOrdersService from 'api/orders/OrdersServices';

interface DeliveryInfosCardProps {
  setOrderDone: Dispatch<SetStateAction<string>>;
  setMessage: Dispatch<SetStateAction<string>>;
  orderComplete: boolean;
}

const DeliveryInfosCard: React.FC<DeliveryInfosCardProps> = ({ setOrderDone, setMessage, orderComplete }) => {
  const [sortedDays, setSortedDays] = useState<DateModel[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const { getCart, clearCart } = useCart();
  const { user } = useUser();
  const { postNewOrder } = useOrdersService();

  const postOrder = async () => {
    const cartItems = getCart().filter(product => product.selected === true);
    const order = {
      companyId: user?.publicMetadata?.crmCompanyId,
      wishDeliveryDate: deliveryDate, //moment(deliveryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      products: cartItems.map((item) => ({
        id: item.productId,
        quantity: item.quantity
      }))
    }
    
    const response = await postNewOrder(order)
    if (response.hasError) {
      setMessage("Une erreur est survenue ! Si le problème persiste contactez notre support : contact@mullo.fr")
    } else {
      clearCart()
      setOrderDone(response.response.expectedDeliveryDate)
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const getDates = () => {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 0 = Dimanche, 1 = Lundi, ..., 6 = Samedi
      const twoAndHalfDaysMs = 2.5 * 24 * 60 * 60 * 1000; // 2.5 jours en millisecondes
    
      function getNextDay(targetDay: number) {
        let date = new Date(today);
        let daysToAdd = (targetDay - dayOfWeek + 7) % 7;
        if (daysToAdd === 0) daysToAdd = 7; // Si aujourd'hui est le targetDay, on prend la semaine suivante
        date.setDate(today.getDate() + daysToAdd);
        return date;
      }
    
      let nextWednesday = getNextDay(3); // 3 = Mercredi
      let nextFriday = getNextDay(5); // 5 = Vendredi
    
      // Vérifier si mercredi est trop proche (moins de 2.5 jours)
      if (nextWednesday.getTime() - today.getTime() < twoAndHalfDaysMs) {
        nextWednesday.setDate(nextWednesday.getDate() + 7);
      }
    
      // Vérifier si vendredi est trop proche (moins de 2.5 jours)
      if (nextFriday.getTime() - today.getTime() < twoAndHalfDaysMs) {
        nextFriday.setDate(nextFriday.getDate() + 7);
      }
    
      const result: DateModel[] = [
        { day: 'mercredi', number: nextWednesday.getDate().toString(), timestamp: nextWednesday.getTime() },
        { day: 'vendredi', number: nextFriday.getDate().toString(), timestamp: nextFriday.getTime() }
      ];
      
      setSortedDays(result)
    };

    getDates();
  }, []);

  const optionsDelivery = sortedDays.map((dateInfo) => ({
    icon: logo,
    deliveryOptionName: 'Livraison au pas de porte',
    deliveryDate: `${dateInfo.day} ${dateInfo.number}/${getMonthFromTimestamp(dateInfo.timestamp)}/${getYearFromTimestamp(dateInfo.timestamp)}`,
    timestamp: `${getYearFromTimestamp(dateInfo.timestamp)}-${String(getMonthFromTimestamp(dateInfo.timestamp)).padStart(2, '0')}-${String(dateInfo.number).padStart(2, '0')}`
  }));

  const handleOptionClick = (index: number, deliveryDate: string) => {
    setSelectedOption(index);
    setDeliveryDate(deliveryDate);
  };

  return (
    <div className="bg-white rounded-lg border border-gray-400 p-6 shadow-sm">
      {/* <h2 className="text-lg font-bold mb-2">Adresse de livraison</h2>
      <div className="flex space-x-4">
        L'adresse de facturation sera précisée sur la facture émise lors de la validation de la commande.
      </div> */}
      <h2 className="text-lg font-bold mb-2">Méthode de livraison</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {optionsDelivery.map((option, index) => (
          <div
            key={index}
            className={`p-2 rounded-lg cursor-pointer transition-all duration-300 flex ${selectedOption === index ? 'bg-indigo-50' : 'bg-white hover:bg-indigo-50'
              }`}
            onClick={() => handleOptionClick(index, option.timestamp)}
          >
            <div className="w-2/5 items-center justify-center hidden sm:flex">
              <img
                className="h-auto w-full"
                src="https://rozho-blog-yby1k94dz0.live-website.com/wp-content/uploads/2024/09/delivery.jpg"
                alt=""
                style={{
                  maxHeight: '100%',
                }}
              />
            </div>

            <div className="w-3/5 pl-4">
              <h3 className="font-bold">{option.deliveryOptionName}</h3>
              <p>Livraison : {option.deliveryDate}</p>
            </div>
          </div>
        ))}
      </div>
      <p className="mt-2 text-sm mb-5">
        Date de livraison estimée : {deliveryDate}
      </p>
      <hr />
      <div className="flex items-start my-4">
        <input
          type="checkbox"
          id="acceptPayment"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="mr-2 mt-1"
        />
        <label htmlFor="acceptPayment" className="text-sm text-gray-700 cursor-pointer">
          En cochant cette case vous acceptez les conditions générales de vente et vous vous engagez à payer la facture sous les 30 jours suivant l'achat.
        </label>
      </div>
      <Button onClick={() => postOrder()} disabled={!isChecked || !deliveryDate || !orderComplete} className="w-full">
        Valider la commande
      </Button>
    </div>
  );
};

export default DeliveryInfosCard;